import React, { createContext, useReducer, ReactNode } from "react";
import { State, Action, ActionTypes } from "./ActionTypes";

export const StoreContext = createContext<any>(null);

const queryParams = new URLSearchParams(window.location.search);
const pageLook = queryParams.get('page_look');

const initialState: State = {
    page_look:              pageLook ? pageLook : "default",
    page_loading:           false,
    loading:                true,
    error:                  false,
    popup:                  "",
    banner:                 "COOKIE_BANNER",
    tealium:                false,
    tealiumVisitorId:       "",
    tealiumVisitorIdGroup:  "",
    tealiumVisitorIdMarket: "",
    data:                   {},
    tnc:                    {},
    pp:                     {}
};

const initialReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.PAGE_LOADING:
            return {
                ...state,
                page_loading: action.loading !== true ? false : true,
                loading: false
            };
        case ActionTypes.LOADING:
            return {
                ...state,
                page_loading: false,
                loading: action.loading !== true ? false : true
            };
        case ActionTypes.RESPONSE:
            return {
                ...state,
                page_loading: false,
                loading: false,
                data: action.data!
            };
        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: true
            };
        case ActionTypes.POPUP:
            return {
                ...state,
                popup: action.popup!,
                banner: ""
            };
        case ActionTypes.BANNER:
            return {
                ...state,
                banner: action.banner!,
            };
        case ActionTypes.TERMS_AND_CONDITIONS:
            return {
                ...state,
                tnc: action.tnc,
            };
        case ActionTypes.PRIVACY_POLICY:
            return {
                ...state,
                pp: action.pp,
            };

        case ActionTypes.TEALIUM:
            return {
                ...state,
                tealium: action.tealium!,
                tealiumVisitorId: action.tealiumVisitorId ? action.tealiumVisitorId : "N/A",
                tealiumVisitorIdGroup: action.tealiumVisitorIdGroup ? action.tealiumVisitorIdGroup : "N/A",
                tealiumVisitorIdMarket: action.tealiumVisitorIdMarket ? action.tealiumVisitorIdMarket : "N/A"
            };
        
        default:
            return state;
    }
};

interface StateProviderProps {
    children: ReactNode;
}

export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(initialReducer, initialState);
    const value = { state, dispatch };
  
    return (
      <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
  };
