import React, { lazy, Suspense } from "react";
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { StateProvider } from './Store/Store';

import { Loading } from "@vfid/lib-id-fe-shared";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<StateProvider>
		<Suspense fallback={<Loading />}>
			<App />
		</Suspense>
	</StateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
