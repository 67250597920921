export interface State {
    page_look:              string,
    page_loading:           boolean,
    loading:                boolean,
    error:                  boolean,
    popup:                  string,
    banner:                 string,
    tealium:                boolean,
    tealiumVisitorId:       string,
    tealiumVisitorIdGroup:  string,
    tealiumVisitorIdMarket: string
    data:                   object,
    tnc:                    object,
    pp:                     object
}

export enum ActionTypes {
    PAGE_LOADING =              "PAGE_LOADING",
    LOADING =                   "LOADING",
    ERROR =                     "ERROR",
    POPUP =                     "POPUP",
    BANNER =                    "BANNER",
    TEALIUM =                   "TEALIUM",
    RESPONSE =                  "RESPONSE",
    TERMS_AND_CONDITIONS =      "TERMS_AND_CONDITIONS",
    PRIVACY_POLICY =            "PRIVACY_POLICY"
}

export interface Action {
    type:                   ActionTypes,
    page_look?:             string,
    page_loading?:          boolean,
    loading?:               boolean,
    error?:                 boolean,
    popup?:                 string,
    banner?:                string,
    tealium?:               boolean,
    tealiumVisitorId:       string,
    tealiumVisitorIdGroup:  string,
    tealiumVisitorIdMarket: string,
    data?:                  object,
    tnc:                    object,
    pp:                     object
}